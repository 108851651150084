<template>
  <div>
    <van-nav-bar :fixed="true" class="nav_bar_view" title="订单详情" left-arrow>
      <template #left>
        <img
          @click="toOrderPage"
          style="width: 18px; height: 18px"
          src="../../assets/left_return_bg.png"
          alt
        />
      </template>
      <template #title>
        <span style="color: #ffffff; font-size: 18px">订单详情</span>
      </template>
      <template #right style="display: flex; flex-direction: row; align-items: center">
        <img
          style="width: 18px; height: 17px; margin-right: 4px"
          src="../../assets/headset_bg.png"
          alt
        />
        <span @click="handleClick" style="font-size: 14px">客服</span>
      </template>
    </van-nav-bar>
    <!--待付款-->
    <div class="wait_title_view" v-show="dataJson.orderStatus === 'NOPAY'">
      <div class="wait_pay_view">
        <img src="../../assets/wait_pay_bg.png" alt />
        <span>等待付款</span>
      </div>
      <div class="daojishi_view">
        <span style="color: white; margin-right: 3px; font-size: 16px">还剩</span>
        <van-count-down :time="dataJson.payLastTime">
          <template #default="timeData">
            <span class="block">{{ timeData.hours }}</span>
            <span class="colon">:</span>
            <span class="block">{{ timeData.minutes }}</span>
            <span class="colon">:</span>
            <span class="block">{{ timeData.seconds }}</span>
          </template>
        </van-count-down>
        <span style="color: white; margin-left: 3px; font-size: 16px">自动关闭订单</span>
      </div>
      <div class="btn_view">
        <van-button class="cancle_order_view" @click="handelCancel(dataJson.id)" round>取消订单</van-button>
        <van-button class="pay_view" @click="popupWindow" round>支付</van-button>
      </div>
    </div>
    <!--待配送-->
    <div
      class="dai_ps_view"
      v-show="
        dataJson.orderStatus === 'NOSHIP' || dataJson.orderStatus === 'NOACCEPT'
      "
    >
      <span>您的包裹正在打包中，即将为您配送</span>
      <van-button class="dai_cancel_order_view" @click="handelCancel(dataJson.id)">取消订单</van-button>
    </div>
    <!--未送达-->
    <div class="dai_ps_view" v-show="dataJson.orderStatus === 'NOREACH'">
      <span>您的包裹上次配送未及时取货</span>
      <span>请重新选择配送时间</span>
      <van-button class="dai_cancel_order_view" @click="handelCancel(dataJson.id)">取消订单</van-button>
    </div>
    <!--配送中-->
    <div
      style="position: relative"
      v-if="
        dataJson.orderStatus === 'NODELIVERY' ||
        dataJson.orderStatus === 'DELIVERYING' ||
        dataJson.orderStatus === 'DELIVERYED'
      "
    >
      <div class="psz_view">
        <div class="icon_view">
          <img src="../../assets/psz_bg.png" alt />
          <span>您的包裹正在配送中</span>
        </div>
        <div class="psz_confirm_btn_view" @click="confirmReceipt(dataJson.id)">确认收货</div>
      </div>
      <div class="con-box con-map">
        <div class="mapbox">
          <div ref="ceshi" id="container" class="map"></div>
          <div class="sign"></div>
        </div>
      </div>
      <div class="contact">
        <div class="contact_view">
          <div
            style="
              height: 35px;
              line-height: 35px;
              padding-left: 10px;
              border-bottom: 1px solid #f2f2f2;
              font-size: 15px;
            "
          >{{ dataJson.deliveryWay }}</div>
          <div
            class="user_info_view"
            style="
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
            "
          >
            <span
              style="
                font-size: 12px;
                color: #333333;
                margin-left: 10px;
                margin-top: 5px;
                display: block;
              "
            >配送员姓名：{{ dataJson.deliveryVo.deliveryName }}</span>
            <div
              style="
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 10px;
              "
            >
              <span style="font-size: 12px; color: black">联系电话：{{ dataJson.deliveryVo.phone }}</span>

              <!-- <a href="tel:13764567708"> -->
              <a :href="'tel:'+dataJson.deliveryVo.phone">
                <img
                  style="width: 24px; height: 24px; margin-right: 20px"
                  src="../../assets/phone_bg.png"
                  alt
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--已完成-->
    <div class="wc_view" v-show="dataJson.orderStatus === 'COMPLETE'">
      <img src="../../assets/success_order_icon.png" />
      <span>您的订单已完成</span>
    </div>

    <!--已取消-->
    <div class="cancel_view" v-show="dataJson.orderStatus === 'CANCLE'">
      <span>您的订单已取消</span>
      <div class="new_pay_view" @click="handelClick">重新购买</div>
    </div>
    <!--收货人区域-->
    <div class="consignee_view">
      <div class="user_info_view">
        <div>
          <span>收货人：{{ dataJson.receiverContact }}</span>
          <span>{{ dataJson.receiverMobile }}</span>
        </div>
        <van-icon v-show="!dataJson.orderStatus === 'NOPAY'" name="arrow" color="#999999" />
      </div>
      <span class="address_view">{{ dataJson.receiverAddress }}</span>
      <div class="big_photo_view">
        <img src="../../assets/big_bg.png" alt />
        <img :src="dataJson.img" alt />
      </div>
    </div>
    <!--送达时间-->
    <div class="delivery_time_view">
      <span class="delivery_text_view">送达时间</span>
      <div>
        <span>{{ deliveryTime }}-{{ this.endTime }}</span>
        <van-icon name="arrow" />
      </div>
    </div>
    <goods-list-item :list="orderList"></goods-list-item>
    <!--查看剩余商品-->
    <div class="total_num_view" v-show="status === 1">
      <div class="total_btn_view">
        <span>共 23 件 查看</span>
        <van-icon name="arrow-down" />
      </div>
    </div>
    <!--商品价格-->
    <div class="pay_price_view">
      <div class="price_view">
        <span class="goods_price_view">商品总价</span>
        <div>
          <span>₱</span>
          <span>{{ dataJson.payMoney }}</span>
        </div>
      </div>
      <div class="price_view">
        <span class="goods_price_view">运费（平台配送）</span>
        <div>
          <span v-show="dataJson.freightFee > 0">₱</span>
          <span>{{ freight(dataJson.freightFee) }}</span>
        </div>
      </div>
      <div class="real_pay_view">
        <span class="goods_price_view" style="font-weight: bold">实付总额</span>
        <div>
          <span style="color: #df3436">₱</span>
          <span style="color: #df3436">{{ dataJson.payMoney }}</span>
        </div>
      </div>
    </div>
    <!--订单编号-->
    <div class="order_sn_view">
      <div class="order_number_view">
        <span>订单编号：{{ dataJson.orderSn }}</span>
        <input id="input" type="text" :value="dataJson.orderSn" />
        <div @click="textCopy">复制</div>
      </div>
      <div class="order_number_view">
        <span>创建时间：{{ dataJson.createTime }}</span>
      </div>
      <div class="order_number_view">
        <span>支付方式：{{ payTypeConvert(dataJson.payType) }}</span>
      </div>
      <div class="order_number_view">
        <span>付款时间：{{ dataJson.payTime }}</span>
      </div>
      <div class="order_number_view">
        <span>发货时间：{{ dataJson.consignTime }}</span>
      </div>
    </div>
    <!--支付-->
    <van-action-sheet class="van_action_sheet_view" v-model="show" title="请选择支付方式">
      <div class="popup_info_view">
        <span style="font-size: 20px; color: #3f3f3f; margin-top: 20px">实付金额</span>
        <label>
          <span>₱</span>
          <span>{{ totalMoney }}</span>
        </label>
        <van-radio-group class="group_view" v-model="payRadio" checked-color="#00B05A">
          <div class="radio_view" @click="isCheck(1)">
            <div>
              <img src="@/assets/alipay_bg.png" alt />
              <span>支付宝</span>
            </div>
            <van-radio class="radio_btn_view" :name="1"></van-radio>
          </div>
          <div class="radio_view" @click="isCheck(2)">
            <div>
              <img src="@/assets/wechat_pay_bg.png" alt />
              <span>微信</span>
            </div>
            <van-radio class="radio_btn_view" :name="2"></van-radio>
          </div>
          <div class="radio_view" @click="isCheck(3)">
            <div>
              <img src="@/assets/hdfk_bg.png" alt />
              <span>货到付款</span>
            </div>
            <van-radio class="radio_btn_view" :name="3"></van-radio>
          </div>
        </van-radio-group>
        <div class="btn_view">
          <van-button @click="selectPay">支付</van-button>
        </div>
      </div>
    </van-action-sheet>
    <!-- <iframe
      id="geoPage"
      width="0"
      height="0"
      frameborder="0"
      style="display: none"
      scrolling="no"
      src="https://apis.map.qq.com/tools/geolocation?key=3AABZ-RG3LX-2LT4M-ZPY3C-OIYVV-RFFWW&referer=shengxian"
    ></iframe>-->
    <van-dialog v-model="code_show" title="扫码支付" show-cancel-button @close="closeEvent">
      <img style="width: 100%" :src="wxCode" />
    </van-dialog>
    <webSocket ref="webSockt"></webSocket>
  </div>
</template>
<script>
import {
  order_getOrderItem,
  order_page,
  order_cancelOrder,
  reach_cash,
  cartAdd,
  app2,
  pay_AliPayH5,
  pay_WxPayH5,
  AlipayWebH5,
  WxWebPayH5,
  confirmArrive, //确认收货
  getShop, //获取商家经纬度
  setLngLat, // 获取配送员和收货地址经纬度
  tencentDriving,
  googleLocation
} from "@/service/api/index.js";
import webSocket from "@/components/webSocket";

import GoodsListItem from "../../components/GoodsListItem";
import { jsonp } from "vue-jsonp";
export default {
  components: {
    GoodsListItem,
    webSocket
  },
  data() {
    return {
      orderId: "",
      dataJson: {},
      status: 1,
      show: false,
      radio: "",
      payRadio: "",
      deliveryTime: "",
      endTime: "",
      headset_icon: "../../assets/headset_bg.png",
      end_img: require("../../assets/end.png"),
      start_img: require("../../assets/start.png"),
      time: 30 * 60 * 60 * 1000,
      orderList: [],
      lat: "",
      lng: "",
      wxCode: "",
      code_show: false,
      userAgent: "",
      business: {
        lat: "",
        lng: ""
      }, //商家经纬度
      receiving: {
        lat: "",
        lng: ""
      }, //收货地经纬度
      totalMoney: ""
    };
  },
  created() {
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
      this.userAgent = "IOS";
    } else if (/(Android)/i.test(navigator.userAgent)) {
      this.userAgent = "这是Android";
    } else {
      this.userAgent = "PC";
    }
  },
  mounted() {
    (function(m, ei, q, i, a, j, s) {
      m[i] =
        m[i] ||
        function() {
          (m[i].a = m[i].a || []).push(arguments);
        };
      (j = ei.createElement(q)), (s = ei.getElementsByTagName(q)[0]);
      j.async = true;
      j.charset = "UTF-8";
      j.src = "https://static.meiqia.com/dist/meiqia.js?_=t";
      s.parentNode.insertBefore(j, s);
    })(window, document, "script", "_MEIQIA");
    _MEIQIA("entId", "3dbc4b43a7a0e28cace3fd0222036982");
    _MEIQIA("withoutBtn");

    order_getOrderItem({ id: this.$route.query.id }).then(res => {
      if (res.status === 200) {
        this.dataJson = res.data;
        this.initMap();
        this.dataJson.img = app2 + "/" + this.dataJson.img;
        this.orderId = res.data.id;
        var starttime = res.data.deliveryTime.replace(
          new RegExp("-", "gm"),
          "/"
        );
        var starttimeHaoMiao = new Date(starttime).getTime(); //得到毫秒数
        this.endTime1 = starttimeHaoMiao;
        var date1 = new Date(this.endTime1);
        var YY1 = date1.getFullYear() + "-";
        var MM1 =
          (date1.getMonth() + 1 < 10
            ? "0" + (date1.getMonth() + 1)
            : date1.getMonth() + 1) + "-";
        var DD1 =
          date1.getDate() < 10 ? "0" + date1.getDate() : date1.getDate();
        var hh1 =
          (date1.getHours() < 10 ? "0" + date1.getHours() : date1.getHours()) +
          ":";
        var mm1 =
          date1.getMinutes() < 10
            ? "0" + date1.getMinutes()
            : date1.getMinutes();
        this.deliveryTime = YY1 + MM1 + DD1 + " " + hh1 + mm1;
        this.endTime = starttimeHaoMiao + 7200000;
        var date = new Date(this.endTime);
        var YY = date.getFullYear() + "-";
        var MM =
          (date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1) + "-";
        var DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        var hh =
          (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) +
          ":";
        var mm =
          date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
        this.endTime = hh + mm;
        this.orderList = (res.data && res.data.orderItemVos) || [];
      }
    });
    this.status = this.$route.params.status;
  },
  methods: {
    initMap() {
      setTimeout(() => {
        if (document.getElementById("container")) {
          getShop().then(res => {
            if (res.status == 200) {
              this.business.lat = Number(res.data.lat); //起
              this.business.lng = Number(res.data.lng);
              setLngLat({ id: this.$route.query.id }).then(req => {
                if (res.status == 200) {
                  this.receiving.lat = Number(req.data.receipt.longitude); //终
                  this.receiving.lng = Number(req.data.receipt.latitude);
                  googleLocation().then(data => {
                    // 定位点位
                    this.position = {
                      lat: this.business.lat,
                      lng: this.business.lng
                    };
                    // 初始化地图
                    this.map = new google.maps.Map(
                      document.getElementById("container"),
                      {
                        center: this.position,
                        zoom: 16
                      }
                    );
                    // 路线服务
                    this.directionsService = new google.maps.DirectionsService(); //请求服务
                    this.directionsRenderer = new google.maps.DirectionsRenderer(); // 回调自动渲染到页面上
                    this.directionsRenderer.setMap(this.map); //自动加载到地图上
                    this.directionsRenderer.setOptions({
                      suppressMarkers: true
                    });
                    this.calculateAndDisplayRoute(
                      this.directionsService,
                      this.directionsRenderer
                    );
                  });
                }
              });
            }
          });
        }
      }, 500);
    },
    // 请求路线服务以及回调
    calculateAndDisplayRoute(directionsService, directionsRenderer) {
      directionsService
        .route({
          // 起点
          origin: {
            location: { lat: this.business.lat, lng: this.business.lng }
          },
          // 终点
          destination: {
            location: { lat: this.receiving.lat, lng: this.receiving.lng }
          },
          // 出行方式  DRIVING 为默认
          travelMode: google.maps.TravelMode.DRIVING
        })
        .then(response => {
          directionsRenderer.setDirections(response);
          // 起点
          new google.maps.Marker({
            position: { lat: this.business.lat, lng: this.business.lng },
            map: this.map,
            icon: {
              url: this.start_img,
              scaledSize: new google.maps.Size(35, 35) // scaled size
            }
          });
          // 终点
          new google.maps.Marker({
            position: { lat: this.receiving.lat, lng: this.receiving.lng },
            map: this.map,
            icon: {
              url: this.end_img,
              scaledSize: new google.maps.Size(35, 35) // scaled size
            }
          });
        })
        .catch(e => window.alert("Directions request failed due to " + status));
    },
    handleClick() {
      _MEIQIA("showPanel");
    },
    handelClick() {
      const { orderItemVos } = this.dataJson;
      const [vos = {}] = orderItemVos;
      const formData = new FormData();
      formData.append("quantity", vos.num);
      formData.append("spuId", vos.spuId);
      formData.append("specIds", vos.specids);
      cartAdd(formData).then(res => {
        if (res.status == 200) {
          this.$router.push({
            path: "/cart"
          });
        }
      });
    },
    handelCancel(id) {
      const formData = new FormData();
      formData.append("orderId", id);
      order_cancelOrder(formData).then(res => {
        if (res.status == 200) {
          this.$router.push({
            name: "Order",
            params: {
              tabIndex: 5
            }
          });
        }
      });
    },
    //确认收货
    confirmReceipt(id) {
      confirmArrive({ id: id }).then(res => {
        if (res.status == 200) {
          this.$router.push({
            name: "Order",
            params: {
              tabIndex: 0
            }
          });
        }
      });
    },
    toOrderPage() {
      this.$router.push("/order");
    },
    popupWindow() {
      this.sn = this.dataJson.orderSn;
      this.totalMoney = this.dataJson.payMoney;
      this.orderId = this.dataJson.id;
      this.show = true;
    },
    freight(data) {
      if (data <= 0) {
        return "包邮";
      } else {
        return data;
      }
    },
    isCheck(e) {
      if (e === 1) {
        this.payRadio = 1;
        this.payType = "ALIPAY";
      } else if (e === 2) {
        this.payRadio = 2;
        this.payType = "WXPAY";
      } else if (e === 3) {
        this.payRadio = 3;
        this.payType = "HUO";
      }
    },
    selectPay() {
      if (this.payType == "ALIPAY" && this.userAgent == "PC") {
        AlipayWebH5({
          sn: this.dataJson.orderSn,
          notifyUrl: `${window.location.protocol}//${window.location.host}/successOrder`
        }).then(res => {
          localStorage.setItem("dom", res.data);
          this.$router.push("zf");
        });
      } else if (this.payType === "ALIPAY") {
        pay_AliPayH5({
          sn: this.dataJson.orderSn,
          returnUrl: `${window.location.protocol}//${window.location.host}/successOrder`,
          quitUrl: `${window.location.protocol}//${window.location.host}/home`
        }).then(res => {
          if (res.status == 200) {
            localStorage.setItem("dom", res.data);
            this.$router.push("zf");
          } else {
            return Toast(res.message);
          }
        });
      } else if (this.payType == "WXPAY" && this.userAgent == "PC") {
        WxWebPayH5({
          sn: this.dataJson.orderSn
        }).then(res => {
          this.wxCode = res.data;
          this.code_show = true;
          this.$refs["webSockt"].webSocketLink();
        });
      } else if (this.payType === "WXPAY") {
        pay_WxPayH5({
          sn: this.dataJson.orderSn
        }).then(res => {
          window.location.href =
            res.data +
            `&redirect_url=` +
            encodeURIComponent(
              `${window.location.protocol}//${window.location.host}/successOrder`
            );
        });
      } else if (this.payType === "HUO") {
        const formData = new FormData();
        formData.append("orderId", this.orderId);
        reach_cash(formData).then(res => {
          if (res.status == 200) {
            this.$router.push({
              path: "/successOrder",
              query: {
                id: this.$route.query.id
              }
            });
          } else {
            Toast(res.message);
          }
        });
      }
    },
    closeEvent() {
      this.$refs["webSockt"].onClose();
    },
    textCopy() {
      var input = document.getElementById("input");
      input.select(); // 选中文本
      document.execCommand("copy"); // 执行浏览器复制命令
    },
    payTypeConvert(data) {
      switch (data) {
        case "WXPAY":
          return "微信";
          break;
        case "WXFRIENDPAY":
          return "微信代付";
          break;
        case "ALIPAY":
          return "支付宝";
          break;
        case "ALIFRIENDPAY":
          return "支付宝代付";
          break;
        case "GCASH":
          return "GCASH";
          break;
        case "OVERAGEPAY":
          return "余额";
          break;
        case "REACHCASH":
          return "货到付款";
          break;
      }
    }
  }
};
</script>

<style lang="less" scoped>
@media screen and (min-width: 768) {
  .nav_bar_view {
    max-width: 540px;
    margin-left: 50%;
    background: red;
    transform: translateX(-50%);
  }
}
// 去掉导航栏底部白线问题
.van-hairline--bottom:after {
  border-bottom-width: 0px;
}
#input {
  position: fixed;
  // top: 0px;
  top: -1000px;
}
.nav_bar_view {
  max-width: 540px;
  width: 100%;
  height: 44px;
  margin-left: 50%;
  transform: translateX(-50%);
  z-index: 10000;
  background: linear-gradient(to bottom, #18b271, #29be71);
  color: white;
}

.wait_title_view {
  width: 100%;
  height: 170px;
  margin-top: 44px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0px 0px 10px 10px;
  background: linear-gradient(to bottom, #29be71, #29be71);
  .wait_pay_view {
    display: flex;
    flex-direction: row;
    margin-top: 18px;
    img {
      width: 26px;
      height: 26px;
      margin-right: 7px;
    }
    span {
      font-size: 16px;
      color: white;
    }
  }
  .daojishi_view {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
    .colon {
      display: inline-block;
      margin: 0 4px;
      font-size: 20px;
      color: white;
    }
    .block {
      display: inline-block;
      width: 32px;
      height: 26px;
      line-height: 26px;
      color: #00b05a;
      font-size: 18px;
      text-align: center;
      border-radius: 4px;
      background-color: white;
    }
  }
  .btn_view {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
    .cancle_order_view {
      width: 128px;
      height: 36px;
      font-size: 13px;
      color: white;
      margin-right: 20px;
      background: linear-gradient(to bottom, #18b271, #29be71);
    }
    .pay_view {
      width: 128px;
      height: 36px;
      background: white;
      color: #00b05a;
      font-size: 13px;
    }
  }
}

.dai_ps_view {
  width: 100%;
  height: 122px;
  display: flex;
  margin-top: 44px;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(to bottom, #29be71, #29be71);
  border-radius: 0px 0px 10px 10px;
  padding-top: 18px;
  span {
    font-size: 16px;
    color: white;
  }
  .dai_cancel_order_view {
    width: 128px;
    height: 36px;
    background: #29be71;
    border: 1px solid white;
    margin-top: 20px;
    line-height: 36px;
    text-align: center;
    border-radius: 18px;
  }
}
.psz_view {
  width: 100%;
  height: 52px;
  display: flex;
  flex-direction: row;
  margin-top: 44px;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(to bottom, #29be71, #29be71);
  border-radius: 0px 0px 10px 10px;
  .icon_view {
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
      width: 26px;
      height: 26px;
      margin-right: 6px;
      margin-left: 26px;
    }
    span {
      font-size: 16px;
      color: white;
    }
  }
  .psz_confirm_btn_view {
    width: 80px;
    height: 28px;
    background: #18b271;
    font-size: 12px;
    margin-right: 28px;
    color: white;
    text-align: center;
    line-height: 28px;
    border: 1px solid white;
    border-radius: 18px;
  }
}
.con-box {
  .mapbox {
    width: 100%;
    height: 256px;
    padding: 10px 0;
    background: #eceeee;
    .map {
      width: 100%;
      height: 256px;
    }
  }
}
.contact {
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 25px;
  // left: 20px;
}
.contact_view {
  width: 280px;
  padding-bottom: 10px;
  background: white;
  border-radius: 10px;
  // position: absolute;
  // bottom: 25px;
  // left: 20px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06);
}
.wc_view {
  width: 100%;
  height: 90px;
  display: flex;
  margin-top: 44px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to bottom, #29be71, #29be71);
  border-radius: 0px 0px 10px 10px;
  img {
    width: 88px;
    height: 68px;
  }
  span {
    font-size: 16px;
    color: white;
    margin-left: 13px;
  }
}
.cancel_view {
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 44px;
  background: linear-gradient(to bottom, #29be71, #29be71);
  border-radius: 0px 0px 10px 10px;
  padding-top: 18px;
  span {
    font-size: 16px;
    color: white;
  }
  .new_pay_view {
    width: 128px;
    height: 36px;
    background: white;
    margin-top: 20px;
    line-height: 36px;
    font-size: 13px;
    color: #00b05a;
    font-weight: bold;
    text-align: center;
    border-radius: 18px;
  }
}

.consignee_view {
  width: 100%;
  height: 174px;
  background: white;
  .user_info_view {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 8px;
    padding-left: 16px;
    padding-right: 9px;
    div {
      span:first-of-type {
        font-size: 16px;
        color: #3f3f3f;
      }
      span:last-of-type {
        font-size: 14px;
        color: #999999;
        margin-left: 20px;
      }
    }
  }
  .address_view {
    width: 300px;
    display: block;
    font-size: 14px;
    color: #3f3f3f;
    margin-left: 15px;
    margin-top: 5px;
  }
  .big_photo_view {
    width: 70px;
    height: 70px;
    margin: 10px auto;
    background: rgb(231, 231, 231);
    position: relative;
    border-radius: 5px;
    img:first-of-type {
      width: 14px;
      height: 14px;
      position: absolute;
      z-index: 2;
      bottom: 2px;
      right: 2px;
    }
    img:last-of-type {
      width: 70px;
      height: 70px;
      position: absolute;
      top: 0;
      left: 0;
      // background: red;
    }
  }
}

.delivery_time_view {
  width: 100%;
  height: 46px;
  margin-top: 10px;
  background: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  .delivery_text_view {
    font-size: 14px;
    color: #3f3f3f;
    margin-left: 12px;
  }
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 12px;
    span {
      font-size: 14px;
      color: #00b05a;
      margin-right: 20px;
    }
  }
}
.goods_list_view {
  width: 100%;
  height: 100px;
  background: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 74px;
    height: 74px;
    margin-left: 6px;
  }
}
.total_num_view {
  width: 100%;
  height: 40px;
  background: white;
  .total_btn_view {
    width: 120px;
    height: 28px;
    background: white;
    border: 0.67px solid #cacacc;
    display: flex;
    justify-content: center;
    font-size: 12px;
    color: #3f3f3f;
    margin: 0px auto;
    flex-direction: row;
    align-items: center;
    border-radius: 18px;
    span {
      margin-right: 4px;
    }
  }
}
.pay_price_view {
  margin-top: 10px;
  width: 100%;
  .price_view {
    width: 100%;
    height: 26px;
    background: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .goods_price_view {
      color: #3f3f3f;
      font-size: 12px;
      margin-left: 12px;
    }
    div {
      margin-right: 12px;
      span:first-of-type {
        font-size: 10px;
        color: #3f3f3f;
      }
      span:last-of-type {
        font-size: 16px;
        color: #3f3f3f;
      }
    }
  }
  .real_pay_view {
    width: 100%;
    height: 46px;
    background: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .goods_price_view {
      color: #484848;
      font-size: 14px;
      margin-left: 12px;
    }
    div {
      margin-right: 12px;
      span:first-of-type {
        font-size: 12px;
        color: #df3436;
      }
      span:last-of-type {
        font-size: 18px;
        color: #df3436;
      }
    }
  }
}
.order_sn_view {
  margin-top: 10px;
  border-bottom: 14px solid #f1f5f6;
  width: 100%;
  .order_number_view {
    width: 100%;
    height: 36px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: white;
    span {
      font-size: 12px;
      color: #3f3f3f;
      margin-left: 12px;
      margin-right: 10px;
    }
    div {
      width: 36px;
      height: 16px;
      background: white;
      border: 0.5px solid #979797;
      border-radius: 3px;
      line-height: 16px;
      font-size: 10px;
      color: #333333;
      text-align: center;
      // cursor: pointer;
    }
  }
}
.van_action_sheet_view {
  max-width: 540px;
  margin-left: 50%;
  transform: translateX(-50%);
}
.popup_info_view {
  width: 100%;
  height: 337px;
  background: white;
  display: flex;
  text-align: center;
  border-top: 0.5px solid #eeeeee;
  flex-direction: column;
  label {
    margin-top: 11px;
    margin-bottom: 40px;
    span:first-of-type {
      font-size: 25px;
      color: #df3436;
    }
    span:last-of-type {
      font-size: 37px;
      color: #df3436;
    }
  }

  .radio_view {
    width: 100%;
    height: 46px;
    display: flex;
    background: white;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #f2f2f2;
    align-items: center;
    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 12px;
      img {
        width: 34px;
        height: 34px;
      }
      span {
        display: block;
      }
    }
    .radio_btn_view {
      margin-right: 12px;
    }
  }
  .btn_view {
    max-width: 540px;
    width: 100%;
    height: 57px;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      width: 100%;
      height: 36px;
      margin-left: 20px;
      margin-right: 20px;
      background: linear-gradient(to right, #01b15b, #49d0a2);
      border-radius: 18px;
      border: none;
      color: white;
      font-size: 13px;
    }
  }
}
.sheet_btn_view {
  max-width: 540px;
  width: 100%;
  height: 57px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    width: 100%;
    height: 36px;
    margin-left: 20px;
    margin-right: 20px;
    background: linear-gradient(to right, #01b15b, #49d0a2);
    border-radius: 18px;
    border: none;
    color: white;
    font-size: 13px;
  }
}
</style>