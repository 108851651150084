<template>
  <div>
    <div class="goods_list_view" v-for="(item, index) in list" :key="index">
      <img :src="imgApp + item.image" alt="" />
      <div
        style="
          width: 100%;
          display: flex;
          flex-direction: column;
          margin-left: 14px;
        "
      >
        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            font-size: 15px;
          "
        >
          <span>{{ item.name }}</span>
          <div style="margin-right: 12px">
            <span style="font-size: 12px; color: #3f3f3f">₱</span>
            <span style="font-size: 18px; color: #3f3f3f; font-weight: bold">{{
              item.money
            }}</span>
          </div>
        </div>
        <span
          style="font-size: 12px; margin-top: 5px; color: #999999"
          v-for="(el, i) in item.specs || []"
          :key="i"
        >
          {{ el.descs }}:{{ el.content }}
        </span>
        <span style="font-size: 14px; margin-top: 13px; color: #3f3f3f"
          >{{ item.price }}x{{ item.num }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { order_getOrderItem, imgApp, order_page } from "@/service/api/index.js";

export default {
  props: {
    list: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      imgApp,
    };
  },
};
</script>

<style lang="less" scoped>
.goods_list_view {
  width: 100%;
  height: 100px;
  background: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 74px;
    height: 74px;
    margin-left: 6px;
  }
}
</style>